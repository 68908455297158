// src/container/Header.tsx
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; 
import './css/Header.css';

interface HeaderProps {
  toggleNightMode: () => void;
  isNightMode: boolean;
}

const Header: React.FC<HeaderProps> = ({ toggleNightMode, isNightMode }) => {
  const { t, i18n } = useTranslation(); 
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    return localStorage.getItem('selectedLanguage') || 'en'; // Default to English
  });

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language); 
    // No URL path change to prevent accumulating language prefixes
  };

  useEffect(() => { 
    localStorage.setItem('selectedLanguage', selectedLanguage);
  }, [selectedLanguage]);  
  
  return (
    <header className='header'>
      <div className="header-left">
        <select value={selectedLanguage} onChange={(e) => handleLanguageChange(e.target.value)}>
          <option value="en">{t('English')}</option>
          <option value="fr-FR">{t('Français')}</option>
          <option value="nl">{t('Nederlands')}</option>
        </select>
      </div>
      <div className="header-right" onClick={toggleNightMode}>
        <label htmlFor="theme" className={`theme ${isNightMode ? 'night' : 'day'}`}>
          <span className="theme__toggle-wrap">
            <input id="theme" className="theme__toggle" type="checkbox" role="switch" name="theme" value="dark" checked={isNightMode} readOnly />
            <span className="theme__icon">
              {[...Array(9)].map((_, index) => (
                <span key={index} className="theme__icon-part"></span>
              ))}
            </span>
          </span>
        </label>
      </div>
    </header>
  );
};

export default Header;
